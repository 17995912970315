import Loadable from "react-loadable";

export default loader => {
  return Loadable({
    loader,
    loading() {
      // return "";
      return (
        <div
          style={{
            width: "100%",
            height: "80vh",
            fontSize: "14px",
            color: "#999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          Loading...
        </div>
      );
    }
  });
};
